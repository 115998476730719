<template>
  <transition name="modal-fade">
    <div
      class="modal align-items-center"
      role="dialog"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="border-0 modal-header justify-content-center">
            <button type="button" class="btn-close" @click="close"></button>
          </div>

          <div class="tw-pb-4 modal-body no-border">
            <div class="row">
              <div class="text-center col-12 meetBox" v-show="isLoading">
                <h5 class="tw-mb-2 bold">
                  {{ $t("nomination.chooseATimeSlotForTheTalentInterview") }}
                  {{ currentTalent?.talent?.basicInfo?.full_name }}
                </h5>
                <div class="book-meeting-mb">
                  
                  <VueCalendly
                    v-if="isLoadingCalendly"
                    :url="`${url}&full_name=${user.first_name}&email=${
                      user.email
                    }&a2=${
                      isNomination
                        ? currentTalent?.talent?.basicInfo?.sharable_link
                        : sharableLink
                    }${
                      phone_number
                        ? '&a1=' + encodeURIComponent(phone_number)
                        : ''
                    }${guests ? '&guests=' + guests : ''}`"
                  ></VueCalendly>
                </div>
              </div>
            </div>
            <div
              class="text-center col-12 meetBox d-flex flex-column justify-content-center"
              v-if="!isLoading"
            >
              <div class="d-flex">
                <skeleton-loader-vue
                  type="rect"
                  :width="800"
                  :height="30"
                  animation="fade"
                  rounded
                />
              </div>

              <br />
              <br />

              <div
                class="w-full m-auto d-flex justify-content-center"
                v-for="index in 10"
                :key="index"
              >
                <skeleton-loader-vue
                  class="mb-4"
                  type="rect"
                  :height="30"
                  :width="500"
                  animation="fade"
                  rounded
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import VueCalendly from "vue-calendly";

export default {
  name: "requestAnInterviewModal",
  props: ["isOpen", "currentTalent", "isNomination"],
  components: {
    VueCalendly: VueCalendly.VueCalendly,
  },
  mounted: function () {
    window.addEventListener("message", this.requestAnInterview);
    this.sharableLink = window.location.href;
    this.getUserData();
  },
  destroyed: function () {
    window.removeEventListener("message", this.requestAnInterview);
  },
  data() {
    return {
      isLoading: false,
      isLoadingCalendly: false,
      phone_number: null,
      guests: null,
      url: null,
      sharableLink: null,
      isRequestAnInterviewLoading: false,
      calendars: null,
      /* "https://calendly.com/squadio/30min?hide_event_type_details=1&hide_gdpr_banner=1" ||
        process.env.VUE_APP_CALENDLY_URL, // ahmed-shibob */
    };
  },
  watch: {
    isOpen: {
      immediate: true,
      deep: true,
      handler(newValue) {
        if (newValue) {
          document.body.classList.add("modal-open");
        } else {
          document.body.classList.remove("modal-open");
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      user: "auth/getUser",
    }),
  },
  methods: {
    async getUserData() {
      const getManagers = await this.axios
        .get(
          this.isNomination
            ? "/api/client/managers"
            : "/api/calendars?meeting_type=talent_selection&is_default=1"
        )
        .then((res) => {
          this.calendars = this.isNomination
            ? res.data.data.recruitment_consultant.calendar
            : res.data.data[0];
          this.url =
            this.calendars.link +
            "?hide_event_type_details=1&hide_gdpr_banner=1";
          //Remove Deal Owner from the Calendly Guest
          // this.guests = [res.data.data.deal_owner.email].join(",");
        });

      const getUser = await this.axios
        .get("/api/users/me")
        .then((res) => {
          this.phone_number = res.data.data.phone_number;
        })
        .catch((err) => {
          console.log(err);
        });

      Promise.all([getManagers, getUser]).then(() => {
        this.isLoadingCalendly = true;
        setTimeout(() => {
          this.isLoading = true;
        }, 1500);
      });
    },
    async requestAnInterview(e) {
      if (this.isRequestAnInterviewLoading) return;
      if (e.data.event == "calendly.event_scheduled") {
        if (e?.data?.payload?.event?.uri) {
          const uriArr = e.data.payload.event.uri.split("/");
          const uuid = uriArr[uriArr.length - 1];
          this.isRequestAnInterviewLoading = true;
          if (this.isNomination) {
            await this.axios
              .post(
                `/api/client/nomination/${this.currentTalent.id}/request-interview`,
                {
                  calendar_id: this.calendars.id,
                  meeting_id: uuid,
                  //talent_id: this.currentTalent.talent.basicInfo.id,
                }
              )
              .then(() => {
                this.$emit("intervewRequested");
                this.close();
              });
          } else {
            await this.axios
              .post(`/api/client/interviews`, {
                calendar_id: this.calendars.id,
                meeting_id: uuid,
                sharable_code: this.$route.params.id,
              })
              .then(() => {
                this.$emit("intervewRequested");
                this.close();
              });
          }
        }
      }
    },
    close() {
      document.body.classList.remove("modal-open");
      this.isRequestAnInterviewLoading = false;
      this.$emit("update", false);
    },
  },
};
</script>

<style>
.modal-open {
  overflow: hidden;
}

.book-meeting-mb {
  min-height: 500px;

  @media (max-width: 786px) {
    margin-bottom: 5rem;
  }
}

.book-meeting-mb .calendly {
  min-height: 500px;
  height: 500px !important;
}

.book-meeting-mb .calendly iframe {
  min-height: 500px;
}

.meetBox .w-full {
  width: 100% !important;
}
</style>
